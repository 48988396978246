import { values } from "lodash";

export const DEFAULT_ALL_ENUM = "all";

export const LANGUAGE_LIST = [
  { label: "ZH", value: "zh-CN" },
  { label: "EN", value: "en-US" },
];

export const LANGUAGE_MAP = {
  'zh-CN': 'zh-cn',
  'en-US': 'en',
};

export const DEFAULT_OPTIONS = {
  mark_id: DEFAULT_ALL_ENUM,
  name: "全部",
  color: "",
  is_default: 1,
};

// export const QUERY_COLUMNS = (global_vars: any) => {
//   return [
//     {
//       ellipsis: true,
//       title: '参数名',
//       dataIndex: 'key',
//       key: 'key',
//     },
//     {
//       title: '参数值',
//       dataIndex: 'value',
//       key: 'value',
//       ellipsis: true,
//       render(text: string) {
//         return replaceVariables(text, global_vars) || '-';
//       },
//     },
//     {
//       ellipsis: true,
//       title: '是否必填',
//       dataIndex: 'not_null',
//       key: 'not_null',
//       render(text: string | number | boolean) {
//         if (typeof text === 'boolean') {
//           return text ? '是' : '否';
//         } else {
//           return `${text}` === '1' ? '是' : '否';
//         }
//       },
//     },
//     {
//       ellipsis: true,
//       title: '参数类型',
//       dataIndex: 'field_type',
//       key: 'field_type',
//       render(text: string) {
//         return text || '-';
//       },
//     },
//     {
//       title: '描述说明',
//       key: 'description',
//       dataIndex: 'description',
//       render(text: string) {
//         return text || '-';
//       },
//     },
//   ]
// }

export const EVENT_COLUMNS = [
  {
    title: "ws_set.events",
    dataIndex: "key",
    key: "key",
  },

  {
    title: "ws_set.description",
    key: "description",
    dataIndex: "description",
    render(text: string) {
      return text || "-";
    },
  },
];

export const WS_SETTING_COLUMNS = [
  {
    title: "request_table.param_name",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "request_table.param_value",
    dataIndex: "value",
    key: "value",
  },
];

export const WS_SETTING_MAP: Record<string, string> = {
  informationSize: "ws_set.content",
  reconnectNum: "ws_set.reconnect",
  reconnectTime: "ws_set.interval_time",
  shakeHandsPath: "ws_set.handshake",
  shakeHandsTimeOut: "ws_set.time_out",
  socketEventName: "",
  socketIoEventListeners: "",
  socketIoVersion: "ws_set.client",
};

export const DESCRIPTION_COLUMNS_PARAMS = {
  title: "schema.description",
  dataIndex: "description",
  width: "20%",
};

export const FILL_TYPES: { [k: string]: string } = {
  left: "tcp_fixed_length.left",
  right: "tcp_fixed_length.right",
};

export const CONTENT_TYPES: { [k: string]: string } = {
  custom: "tcp_fixed_length.custom",
  common: "tcp_fixed_length.general",
};

export const COMMON_CONTENT_TYPES: { [k: string]: string } = {
  1: "delimiter_detail.space",
  2: "delimiter_detail.zero",
  3: "delimiter_detail.hash",
  4: "delimiter_detail.plus",
  5: "delimiter_detail.comma",
  6: "delimiter_detail.pipe",
  7: "delimiter_detail.semicolon",
  8: "delimiter_detail.tab",
  9: "delimiter_detail.colon",
  10: "delimiter_detail.newline",
  11: "delimiter_detail.return",
  12: "delimiter_detail.newline_return",
};

export const RULE_TYPES: { [k: string]: string } = {
  1: "delimiter_detail.comma",
  2: "delimiter_detail.pipe",
  3: "delimiter_detail.semicolon",
  4: "delimiter_detail.tab",
  5: "delimiter_detail.space",
  6: "delimiter_detail.colon",
  7: "delimiter_detail.newline",
  8: "delimiter_detail.return",
  9: "delimiter_detail.newline_return",
};

export const TCP_SETTING_MAP: Record<string, string> = {
  removePacketHeader: "function.package_header",
  calcLengthToPacketHeader: "function.package_header",
  removeWrapChar: "function.character",
  addCharToPacketEnd: "function.character",
  parseXmlToJson: "",
};

export const TCP_PARAM_NAME_MAP: Record<string, string> = {
  calcLengthToPacketHeader: "function.content_length",
  addCharToPacketEnd: "function.package_tail",
  removeWrapChar: "function.line_ending",
  removePacketHeader: "function.remove_header",
  parseXmlToJson: "function.xml",
};

export enum REQUEST_BODY_RAW_MODE_ENUM {
  JSON = "json",
  XML = "xml",
  JAVASCRIPT = "javascript",
  PLAIN = "plain",
  HTML = "html",
}

export const REQUEST_RAW_MODE_LIST = values(REQUEST_BODY_RAW_MODE_ENUM);

export enum ReceiveOptionsEnum {
  none = "none",
  endedChar = "endedChar",
  endedHexChar = "endedHexChar",
  endedHeaderByteLength = "endedHeaderByteLength",
  endedContentByteLength = "endedContentByteLength",
}

export const receiveOptions = [
  {
    label: "receive_detail.receive_message",
    value: ReceiveOptionsEnum.none,
    correlationTitle: "",
  },
  {
    label: "receive_detail.text_message",
    value: ReceiveOptionsEnum.endedChar,
    correlationTitle: "字符",
  },
  {
    label: "receive_detail.binary_message",
    value: ReceiveOptionsEnum.endedHexChar,
    correlationTitle: "包尾字符（16进制字符）",
  },
  {
    label: "receive_detail.format_message",
    value: ReceiveOptionsEnum.endedHeaderByteLength,
    correlationTitle: "包头字节长度",
  },
  {
    label: "receive_detail.fixed_length",
    value: ReceiveOptionsEnum.endedContentByteLength,
    correlationTitle: "报文子节长度",
  },
];

export enum Overall {
  PARAMS = "0",
  CODE = "1",
}

export const OVERALL_INFO = [
  {
    name: "left.public_parameters",
    target_id: Overall.PARAMS,
    method: "",
    children: [],
  },
  {
    name: "left.code",
    target_id: Overall.CODE,
    method: "",
    children: [],
  },
];

export enum AUTH_ENUM {
  NOAUTH = "noauth",
  KV = "kv",
  BEARER = "bearer",
  BASIC = "basic",
  DIGEST = "digest",
  OAUTH1 = "oauth1",
  OAUTH2 = "oauth2",
  HAWK = "hawk",
  AWSV4 = "awsv4",
  NTLM = "ntlm",
  EDGEGRID = "edgegrid",
}

export const AUTH_CONFIG: (auth?: any) => { [k: string]: any } = (auth?: any) => {
  switch (auth?.type) {
    case "noauth":
      return {
        title: "noauth",
        content: "",
        example: "",
      };
    case "inherit":
      return {
        title: "from_parent",
        content: "",
        example: "",
      };
    case "kv":
      return {
        title: "key_value",
        content: auth?.kv?.in === "header" ? "to_header" : "to_query",
        example: "key: 2jhskfdgjldfgjldf-9639-kiuwoiruk",
      };
    case "bearer":
      return {
        title: "Bearer Token",
        content: "bearer",
        example: "Authorization: Bearer your_access_token",
      };
    case "basic":
      return {
        title: "Basic auth",
        content: "basic",
        example: "Authorization: Basic dXNlcjpwYXNzd29yZA==",
      };
    case "digest":
      return {
        title: "Digest auth",
        content: "digest",
        example:
          'Authorization: Digest username="user", realm="Example", nonce="abc123", uri="/uri", algorithm=MD5, response="d41d8cd98f00b204e9800998ecf8427e", opaque="xyz789"',
      };
    case "oauth1":
      return {
        title: "OAuth 1.0",
        content: `${auth?.oauth1?.addParamsToHeader ? "to_header" : "oauth1_body"}`,
        example: `${
          auth?.oauth1?.addParamsToHeader
            ? 'Authorization: OAuth oauth_consumer_key="dpf43f3p2l4kfa7", oauth_nonce="kYjzVBB8Y0ZFabxSWbWovY3uYSQ2pTgZeR2ZS4cg", oauth_signature="tnnArxj06cWHq44gCs1OSKk%2FJLY%3D", oauth_signature_method="HMAC-SHA1", oauth_timestamp="1318622958", oauth_token="370773112-GmHxMAgYyLbNEtIKZeRNFsMKPR9EyMZeS9weJAEb", oauth_version="1.0"'
            : "?oauth_consumer_key=YOUR_CONSUMER_KEY&oauth_token=YOUR_ACCESS_TOKEN&oauth_signature_method=HMAC-SHA1&oauth_timestamp=1453100844&oauth_nonce=W6qTKz&oauth_version=1.0&oauth_signature=YOUR_SIGNATURE"
        }`,
      };
    case 'oauth2':
      return {
        title: 'OAuth 2.0',
        content: `${
          auth?.oauth2?.addTokenTo === 'header'? "to_header" : "to_query"
        }`,
        example: '',
      };
    case "hawk":
      return {
        title: "Hawk Authentication",
        content: "hawk",
        example:
          'Authorization: Hawk id="dh37fgj492je", ts="1353832234", nonce="k3j4h2", ext="hello", mac="6R4rV5iE+NPoym+WwjeHzjAGXUtLNIxmo1vp/s="',
      };
    case "awsv4":
      return {
        title: "AWS Signature",
        content: auth?.awsv4?.addAuthDataToQuery ? "aws_url" : "aws_header",
        example: `${
          auth?.awsv4?.addAuthDataToQuery
            ? "Authorization: AWS4-HMAC-SHA256 Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request, SignedHeaders=host;x-amz-date, Signature=fe5f80f844c41955f23530d0954043ce1843cc98b513ac5a408975949f119d23<br />X-Amz-Date: 20130524T00:00:00Z<br />X-Amz-Security-Token: FQoDYXdzECkaDAs....."
            : "X-Amz-Date=20221026T093522Z&X-Amz-Algorithm=AAWS4-HMAC-SHA256&X-Amz-Credential=abc%2F20221026%2Fus-east-1%2Fexecute-api%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=a64ee71d002b9937788ef23c018bd7ef38956dc45bfec17abf6eab1a4e113a05"
        }`,
      };
    case "ntlm":
      return {
        title: "NTLM Authentication",
        content: "ntlm",
        example: "Authorization: NTLM TlRMTVNTUAABAAAAAABIIEog...",
      };
    case "edgegrid":
      return {
        title: "Akamai EdgeGrid",
        content: "akamai",
        example:
          'Authorization: EG1-HMAC-SHA256 client_token="YOUR_CLIENT_TOKEN", access_token="YOUR_ACCESS_TOKEN", timestamp=1616700900, nonce="abc123", signature="YOUR_GENERATED_SIGNATURE"',
      };
    case "jwt":
      return {
        title: "Jwt Bearer",
        content: `${auth?.jwt?.addTokenTo === "header" ? "jwt_header" : "jwt_query"}`,
        example: `${auth?.jwt?.addTokenTo === "header" ? "Authorization: Bearer your_token" : "?token=your_token"}`,
      };
    case "asap":
      return {
        title: "ASAP(Atlassian)",
        content: "akamai",
        example: "",
      };
    default:
      return {};
  }
};

export const getAuthInfo = (auth: any) => {
  switch (auth?.type) {
    case "noauth":
      return {
        title: "noauth",
      };
    case "inherit":
      return {
        title: "from_parent",
      };
    case "kv":
      return {
        title: "key_value",
        content: auth?.kv?.in === "header" ? "to_header" : "to_query",
        example: "key: 2jhskfdgjldfgjldf-9639-kiuwoiruk",
      };
    case "bearer":
      return {
        title: "Bearer Token",
        content: "bearer",
        example: "Authorization: Bearer your_access_token",
      };
    case "basic":
      return {
        title: "Basic auth",
        content: "basic",
        example: "Authorization: Basic dXNlcjpwYXNzd29yZA==",
      };
    case "digest":
      return {
        title: "Digest auth",
        content: "digest",
        example:
          'Authorization: Digest username="user", realm="Example", nonce="abc123", uri="/uri", algorithm=MD5, response="d41d8cd98f00b204e9800998ecf8427e", opaque="xyz789"',
      };
    case "oauth1":
      return {
        title: "OAuth 1.0",
        content: `${auth?.oauth1?.addParamsToHeader ? "to_header" : "oauth1_body"}`,
        example: `${
          auth?.oauth1?.addParamsToHeader
            ? 'Authorization: OAuth oauth_consumer_key="dpf43f3p2l4kfa7", oauth_nonce="kYjzVBB8Y0ZFabxSWbWovY3uYSQ2pTgZeR2ZS4cg", oauth_signature="tnnArxj06cWHq44gCs1OSKk%2FJLY%3D", oauth_signature_method="HMAC-SHA1", oauth_timestamp="1318622958", oauth_token="370773112-GmHxMAgYyLbNEtIKZeRNFsMKPR9EyMZeS9weJAEb", oauth_version="1.0"'
            : "?oauth_consumer_key=YOUR_CONSUMER_KEY&oauth_token=YOUR_ACCESS_TOKEN&oauth_signature_method=HMAC-SHA1&oauth_timestamp=1453100844&oauth_nonce=W6qTKz&oauth_version=1.0&oauth_signature=YOUR_SIGNATURE"
        }`,
      };
    case "hawk":
      return {
        title: "Hawk Authentication",
        content: "hawk",
        example:
          'Authorization: Hawk id="dh37fgj492je", ts="1353832234", nonce="k3j4h2", ext="hello", mac="6R4rV5iE+NPoym+WwjeHzjAGXUtLNIxmo1vp/s="',
      };
    case "awsv4":
      return {
        title: "AWS Signature",
        content: auth?.awsv4?.addAuthDataToQuery ? "aws_url" : "aws_header",
        example: `${
          auth?.awsv4?.addAuthDataToQuery
            ? "Authorization: AWS4-HMAC-SHA256 Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request, SignedHeaders=host;x-amz-date, Signature=fe5f80f844c41955f23530d0954043ce1843cc98b513ac5a408975949f119d23<br />X-Amz-Date: 20130524T00:00:00Z<br />X-Amz-Security-Token: FQoDYXdzECkaDAs....."
            : "X-Amz-Date=20221026T093522Z&X-Amz-Algorithm=AAWS4-HMAC-SHA256&X-Amz-Credential=abc%2F20221026%2Fus-east-1%2Fexecute-api%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=a64ee71d002b9937788ef23c018bd7ef38956dc45bfec17abf6eab1a4e113a05"
        }`,
      };
    case "ntlm":
      return {
        title: "NTLM Authentication",
        content: "ntlm",
        example: "Authorization: NTLM TlRMTVNTUAABAAAAAABIIEog...",
      };
    case "edgegrid":
      return {
        title: "Akamai EdgeGrid",
        content: "akamai",
        example:
          'Authorization: EG1-HMAC-SHA256 client_token="YOUR_CLIENT_TOKEN", access_token="YOUR_ACCESS_TOKEN", timestamp=1616700900, nonce="abc123", signature="YOUR_GENERATED_SIGNATURE"',
      };
    case "jwt":
      return {
        title: "Jwt Bearer",
        content: `${auth?.jwt?.addTokenTo === "header" ? "jwt_header" : "jwt_query"}`,
        example: `${auth?.jwt?.addTokenTo === "header" ? "Authorization: Bearer your_token" : "?token=your_token"}`,
      };
    case "asap":
      return {
        title: "ASAP(Atlassian)",
        content: "akamai",
        example: "",
      };
    default:
      return {};
  }
};

export const tcpMethodHeaders = {
  iso8583: ['域编号', '域', '域值', '描述'],
  fixed_message: ['参数名', '参数值', '报文定长规则', '描述'],
  delimiter_message: ['参数名', '参数值', '分隔符', '描述'],
};

export const tcpBodyMap = {
  iso8583: "ISO 8583",
  json: "JSON",
  xml: "XML",
  fixed_message: "receive_detail.fixed_length",
  delimiter_message: "receive_detail.separated",
  raw: "raw",
};
